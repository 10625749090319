import React from "react";
import { useLocation, Navigate } from "react-router-dom";

type Props = {
  children: any;
};

export const RedirectWithoutTrailingSlash: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const path = location.pathname;
  // Check if the path is missing a trailing slash and does not include "online-order"
  if (
    !path.endsWith("/") &&
    path !== "/" &&
    !path.includes("order-food-online")
  ) {
    return <Navigate to={`${path}/`} />;
  }

  return children;
};
