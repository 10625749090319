import { QueryClient } from "@tanstack/react-query";
import { Locales } from "src/i18n/types";
import { PickUpPoint, Price } from "src/_generated";

export enum MODELS {
  LOCALE = "locale",
  NAVBAR_EXPAND = "navbar_expand",
  USER_EXIST = "user_exist",
  PRICES = "prices",
  TAGS = "tags",
  PICK_UP_POINT = 'pick_up_pont'
}

export const store = {
  get: <T>(client: QueryClient, model: MODELS) =>
    client.getQueryData<T>([model]),
  locale: (client: QueryClient, data: Locales) => {
    client.setQueryData([MODELS.LOCALE], () => data);
  },
  userExist: (client: QueryClient, data: boolean) => {
    client.setQueryData([MODELS.USER_EXIST], () => data);
  },
  navbarExpand: (client: QueryClient, data: boolean) => {
    client.setQueryData([MODELS.NAVBAR_EXPAND], () => data);
  },
  prices: (client: QueryClient, data: Price) => {
    client.setQueryData([MODELS.PRICES], () => data);
  },
  tags: (client: QueryClient, data: []) => {
    client.setQueryData([MODELS.TAGS], () => data);
  },
  points: (client: QueryClient, data: {result:PickUpPoint[], errors:any[]}) => {
    client.setQueryData([MODELS.PICK_UP_POINT], () => data);
  },
  clear: (client: QueryClient) => client.clear(),
} as const;
