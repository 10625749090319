import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  ReactNode,
} from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { removeCjeventData, setCookie } from "src/utils/affilate";
import { useMutation } from "@tanstack/react-query";
import { api } from "src/fetcher/api";

type InitialState = {
  isAffiliate: boolean;
  affiliateId: null | string;
};

type CjeventBody = {
  fp: string;
  cjevent: string;
};

type DataType = {
  error: {
    status: boolean;
    msg: string;
  };
  result: {
    isAffilate: boolean;
    cjevent: null | string;
  };
  success: boolean;
};

type Props = {
  children: ReactNode;
};

export const initialState: InitialState = {
  isAffiliate: false,
  affiliateId: null,
};

const AffiliateContext = createContext(initialState);

const AffiliateProvider: React.FC<Props> = ({ children }) => {
  const [visitorId, setVisitorId] = useState(null);
  const [affilate, setAffilate] = useState<InitialState>({
    isAffiliate: false,
    affiliateId: null,
  });

  const Global = typeof document !== `undefined` && document;
  let urlString = Global?.location?.href;
  let url = urlString && new URL(urlString);
  const cjEvent = url && url.searchParams.get("cjevent");
  const hasKeyWord = !!cjEvent;

  const cjeventMutation = useMutation(
    (body: CjeventBody) => api["affiliate/visitor"].POST({ Body: body }),
    {
      onSuccess: (data: DataType) => {
        const { success } = data;
        if (success) {
          setCookie("cje", cjEvent, { secure: true });
          setAffilate((prev) => ({
            ...prev,
            isAffiliate: true,
            affiliateId: data.result.cjevent,
          }));
        } else {
          removeCjeventData();
          setAffilate((prev) => ({
            ...prev,
            isAffiliate: false,
            affiliateId: null,
          }));
        }
      },
      onError: (error: string) =>
        console.error("Error while posting cjevent:", error),
    }
  );

  const getCjEventMutation = useMutation(
    (visitorId: string) =>
      api["affiliate/visitor/:id"].GET({
        Params: {
          id: visitorId,
        },
      }),
    {
      onSuccess: (data: DataType) => {
        const {
          success,
          result: { cjevent },
        } = data;
        if (success === true && cjevent !== null) {
          setAffilate((prev) => ({
            ...prev,
            isAffiliate: true,
            affiliateId: data.result.cjevent,
          }));
        } else {
          removeCjeventData();
          setAffilate((prev) => ({
            ...prev,
            isAffiliate: false,
            affiliateId: null,
          }));
        }
      },
      onError: (error: string) => {
        console.error("Error while getting cjevent:", error);
      },
    }
  );

  const handleSetAffiliate = useCallback(async () => {
    if (hasKeyWord) {
      const body = {
        fp: String(visitorId),
        cjevent: String(cjEvent),
      };
      cjeventMutation.mutate(body);
    } else {
      getCjEventMutation.mutate(visitorId);
    }
  }, [hasKeyWord, visitorId]);

  const initPrint = async () => {
    try {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setVisitorId(result.visitorId);
    } catch (error) {
      console.error("Error while initializing fingerprint:", error);
    }
  };

  useEffect(() => {
    void initPrint();
  }, []);

  useEffect(() => {
    if (visitorId) {
      void handleSetAffiliate();
    }
  }, [visitorId]);

  return (
    <AffiliateContext.Provider value={affilate}>
      {children}
    </AffiliateContext.Provider>
  );
};

export { AffiliateProvider, AffiliateContext };
