import React, { Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import {
  SwitchMode,
  allPages,
  allowedPages,
  makeHierarchicalPath,
} from "src/navigation";
import { PAGES } from "src/components/routes/types";
import { pages, pagesRedirect } from "src/components/routes";
import { Preloader } from "src/components/atoms/Preloader";

const AuthGate: React.FC<SwitchMode & { scheme: PAGES }> = ({
  mode,
  scheme,
}) => {
  if (!allowedPages[mode].includes(scheme)) {
    const url = pagesRedirect[mode];
    const to = makeHierarchicalPath(
      undefined,
      Array.isArray(url) ? url : [url],
      "replace"
    );

    if (to) {
      return <Navigate replace to={to} />;
    }
  }

  const Component = pages[scheme].component;
  return <Component />;
};

export const PagesSwitch: React.FC<SwitchMode> = ({ mode }) => {
  const location = useLocation();

  return (
    <Suspense fallback={<Preloader />}>
      <Routes
        location={{
          ...location,
          pathname: location.pathname.split("/~/")[0],
        }}
      >
        <Route>
          {allPages.map(scheme => (
            <Route
              key={scheme}
              path={scheme}
              element={<AuthGate mode={mode} scheme={scheme} />}
            />
          ))}
        </Route>
      </Routes>
    </Suspense>
  );
};
