export function getCookie(name: string): string | undefined {
  let matches: RegExpMatchArray | null;
  if (typeof document !== "undefined") {
    matches = document.cookie.match(
      new RegExp(
        `(?:^|; )${name.replace(/([.$?*|{}()[\]\\\/+^])/g, "\\$1")}=([^;]*)`
      )
    );
  }

  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie(
  name: string,
  value: string,
  options: { [key: string]: any } = {}
): void {
  const expDays: number = 30;

  let date: Date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);

  options = {
    path: "/",
    expires: date.toUTCString(),
    SameSite: 'Lax',
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie: string =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export function removeCjeventData() {
  if (typeof document !== `undefined`) {
    const scripts = document.querySelectorAll("[data-cje]");
    Array.from(scripts).map((script) => {
      script.innerHTML = "";
    });
  }
}
