import { matchPath } from "react-router-dom";
import { compile } from "path-to-regexp";

import { PAGES, Scheme } from "src/components/routes/types";
import { PageRoute, Roles, pages as pagesConfig } from "src/components/routes";

type Route = PageRoute;

type RouteWithScheme = Route & { scheme: PAGES };
type Routes = Record<Roles, string[]>;
type ParsedPathname = string[];
type ParsedSearchParams = Record<string, string>;
export type SwitchMode = {
  mode: Roles;
};

export const prepareURL = (pathname: string) => {
  const path = pathname.split("/~")[0] as PAGES;
  const pages = Object.values(PAGES).filter((item) => item !== "*");

  if (pages.includes(path)) {
    return path;
  }

  const match = pages
    .map((scheme) => matchPath(path, scheme))
    .find((item) => item);

  return match?.pathname as PAGES | undefined;
};

const allRoutes = (config: Record<string, Route>): RouteWithScheme[] =>
  Object.keys(config).map(
    (scheme: string) => ({ scheme, ...config[scheme] } as never)
  );

const filterConfig = (list: RouteWithScheme[], access: Roles): string[] =>
  list.filter((item) => item.access === "all").map((item) => item.scheme);

const createRoutes = (): Routes => {
  const list = allRoutes(pagesConfig);

  return {
    guest: filterConfig(list, "guest"),
  };
};

export const allPages: PAGES[] = allRoutes(pagesConfig).map(
  (item) => item.scheme
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) as any;
export const allowedPages: Routes = createRoutes();
export type Path = {
  pathname: string;
  search?: string;
};

const DELIMITER = "/~";
const allPagesKeys = Object.values(PAGES);

const compilePathname = (
  prev: ParsedPathname,
  scheme: Scheme<PAGES>["scheme"],
  params: Scheme<PAGES>["params"]
): ParsedPathname => {
  const isCompilable = scheme.includes(":") || params;
  const compiled = isCompilable ? compile(scheme)(params) : scheme;
  const filtered = prev.filter((item) => item !== compiled);

  if (isPage(scheme) && filtered.length) {
    throw new Error("Use option `mode=replace`");
  }

  return [...filtered, compiled];
};

export const getRouteConfigByPath = (path?: string): Partial<PageRoute> =>
  pagesConfig[path as PAGES] || {};

const parsePathname = (path = ""): ParsedPathname => {
  const parts = path.split(DELIMITER).filter((item) => !!item);
  return path.startsWith(DELIMITER) ? ["/", ...parts] : parts;
};

const parseQueryString = (path = ""): ParsedSearchParams =>
  path
    .slice(1)
    .split("&")
    .reduce((acc, item) => {
      const [key, value] = item.split("=");
      return key ? { ...acc, [key]: value } : acc;
    }, {});

const serializeQueryString = (params: ParsedSearchParams): string | undefined =>
  Object.keys(params).length
    ? `?${Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")}`
    : undefined;

const isPage = (path: string) => allPagesKeys.includes(path as never);

export const makeHierarchicalPath = (
  from: Path | undefined,
  to: Scheme<PAGES>[],
  mode: "replace" | "detect"
): Path | null => {
  if (!to || !to.length) {
    return null;
  }

  try {
    const precompiled = to.reduce<{
      pathname: ParsedPathname;
      search: ParsedSearchParams;
    }>(
      (acc, { scheme, params, getParams }) => {
        acc.pathname = compilePathname(acc.pathname, scheme, params);

        if (getParams) {
          acc.search = { ...acc.search, ...getParams };
        }

        return acc;
      },
      mode === "replace" || (mode === "detect" && isPage(to[0].scheme))
        ? { pathname: [], search: {} }
        : {
            pathname: parsePathname(from?.pathname),
            search: parseQueryString(from?.search),
          }
    );
    return {
      pathname: precompiled.pathname.join(DELIMITER).replaceAll("//", "/"),
      search: serializeQueryString(precompiled.search),
    };
  } catch (error) {
    console.error("makeURL", error);
    return null;
  }
};
